/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W latach 2022-2023 roku firma Transpol Lider zrealizowała inwestycję przebudowy drogi powiatowej nr 3231P w miejscowości Kawnice."), "\n", React.createElement(_components.p, null, "W ramach zadania realizowane zostały:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "odtworzenie trasy i punktów wysokościowych"), "\n", React.createElement(_components.li, null, "usunięcie drzew i krzaków"), "\n", React.createElement(_components.li, null, "rozbiórka elementów dróg"), "\n", React.createElement(_components.li, null, "regulacja wysokościowa zasuw i studni"), "\n", React.createElement(_components.li, null, "wykonanie podbudowy"), "\n", React.createElement(_components.li, null, "wykonanie nawierzchni z betonu asfaltowego oraz z betonowej kostki brukowej dla chodników i zjazdów"), "\n", React.createElement(_components.li, null, "wykonanie oznakowania poziomego i pionowego"), "\n", React.createElement(_components.li, null, "ustawienie krawężników betonowych oraz chodników z płyt wskaźnikowych"), "\n", React.createElement(_components.li, null, "nasadzenie zieleni drogowej, drzew i krzewów"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
